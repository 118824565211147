<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Pasos del proceso de Calidad {{ proceso.nombre }}</v-card-title>
      <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        ><CIcon name="cil-user" /> Nuevo</CButton
      >

      <v-card-text>
        <div class="text-center" v-if="loadingTiposOperacion">
          <div class="spinner-border" role="status"></div>
        </div>
        <filters
          v-if="!loadingTiposOperacion"
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="items"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
          <template #activo="data">
            <td>
              <CBadge :color="data.item.activo ? 'success' : 'secondary'">
                {{ data.item.activo == 1 ? "Habilitado" : "Inhabilitado" }}
              </CBadge>
            </td>
          </template>
          <template #acciones="data">
            <td>
              <CLink
                href="#"
                @click="editar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Editar'"
              >
                <CIcon name="cil-pencil" />
              </CLink>
              <CLink
                href="#"
                @click="preEliminar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Eliminar'"
              >
                x
              </CLink>
            </td>
          </template>
        </CDataTable>
      </v-card-text>
    </v-card>
    <br /><br />
    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea eliminar el registro?
        </v-card-title>

        <v-card-text>
          {{deleteItem?deleteItem.id:''}} - {{deleteItem?deleteItem.nombre:''}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn color="gray darken-1" text @click="eliminar"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Filters from "@/components/filters";
import PasoProcesoCalidadService from "@/services/pasoProcesoCalidad.service";
import ListaService from "@/services/lista.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Users",
  data() {
    return {
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "nombre", label: "Nombre", _classes: "font-weight-bold" },
        { key: "clase_paso_nombre", label: "Clase" },
        { key: "campo_nombre", label: "Campo" },
        { key: "cumplePasosSiguientes", label: "Cumple" },
        { key: "noCumplePasosSiguientes", label: "No Cumple" },
        { key: "activo", label: "Estado" },
        { key: "acciones", label: "Acciones" },
      ],
      filterFields: [
        { value: "id", label: "Código" },
        { value: "nombre", label: "Nombre" },
        {
          value: "activo",
          label: "Estado",
          options: [
            { id: 1, name: "Habilitado" },
            { id: 0, name: "Inhabilitado" },
          ],
        },
      ],
      proceso: {
        nombre: undefined
      },
      activePage: 1,
      loading: false,
      loadingTiposOperacion: false,
      message: "",
      dialog: false,
      deleteItem: undefined,
    };
  },
  components: {
    Filters,
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Definición de proceso de calidad"))
      this.$router.push("/dashboard");
    
    if (localStorage.getItem("datos_proceso_calidad")) {
      this.proceso = JSON.parse(localStorage.getItem("datos_proceso_calidad"));
      this.fetchItems("");
    }
    
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda);
    });

  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      PasoProcesoCalidadService.getItems(this.proceso.id, cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          // this.items = this.items.map(function f(x) {
          //   return { ...x, layout_nombre: x.layout.nombre };
          // });
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    crear() {
      localStorage.removeItem("datos_paso_proceso_calidad");
      this.$router.push({ path: `pasos/editar/` });
    },
    editar(item) {
      localStorage.setItem("datos_paso_proceso_calidad", JSON.stringify(item));
      this.$router.push({ path: `pasos/editar/` });
    },
    preEliminar(item) {
      this.deleteItem = item;
      this.dialog = true;
    },
    eliminar() {
      this.loading = true;
      this.dialog = false;
      PasoProcesoCalidadService.deleteItem(this.proceso.id, this.deleteItem.id).then(
          (response) => {
            console.log(response.data);
            this.fetchItems("");
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
